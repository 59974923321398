<template>
	<div>
		<van-image width="100vw" :src="courseInfo.courseInfo.coursePicture" />
		<div class="course">
			<h3>{{ courseInfo.courseInfo.courseName }}</h3>
			<p>场景：{{ courseInfo.courseSite.siteName }}</p>
			<p>地址：{{ courseInfo.courseSite.siteAddress }}</p>
			<p>上课教师：{{ courseInfo.teacherInfo.teacherName }}</p>
			<p>适宜年级：{{ courseInfo.courseInfo.courseLimitGrade }}年级</p>
			<p>限制人数：{{ courseInfo.courseRule.selectMaxNumber }}人</p>
			<div
				:style="{ color: '#888', lineHeight: '1.5em' }"
				v-if="courseInfo.courseInfoTime[0].timeType == '2'"
			>
				上课时间：
				<div>
					<p
						:style="{
							textIndent: '2em',
						}"
						v-for="(item, i) in courseInfo.courseInfoTime"
						:key="i"
					>
						<span style="margin-right: 10px">{{ item.date }}</span>
						<span>{{ item.startTime }} 至 </span>
						<span>{{ item.endTime }}</span>
					</p>
				</div>
			</div>
			<p v-if="courseInfo.courseInfoTime[0].timeType != '2'">
				上课时间：{{ courseInfo.courseInfoTime[0].startDay }}至{{
					courseInfo.courseInfoTime[0].endDay
				}},每周{{ courseInfo.courseInfoTime[0].dayOfWeek }},{{
					courseInfo.courseInfoTime[0].startTime
				}}至{{ courseInfo.courseInfoTime[0].endTime }}
			</p>
			<p class="info">{{ courseInfo.courseInfo.courseIntroduction }}</p>
		</div>
		<div
			class="richText ql-editor"
			v-html="courseInfo.courseInfo.courseDetail"
		></div>
	</div>
</template>

<script>
import { getCourseInfo_api } from '@/api/course'
import moment from 'moment'
export default {
	data() {
		return {
			courseInfo: {},
		}
	},
	methods: {
		async getCourseInfo() {
			let { data, code } = await getCourseInfo_api({
				courseId: this.$route.query.courseId,
			})
			if (code === 200) {
				data.courseInfo.courseLabel = data.courseInfo.courseLabel.split(
					','
				)
				// 处理时间
				// if (data.courseInfoTime[0].timeType == 1) {
				//   data.time =
				//     moment(new Date(data.courseStartWeek)).format("YYYY-MM-DD") +
				//     " 至 " +
				//     moment(new Date(data.courseEndWeek)).format("YYYY-MM-DD") +
				//     " 每周" +
				//     data.courseDay +
				//     " " +
				//     moment(new Date(data.courseStartTime)).format("HH:mm") +
				//     "-" +
				//     moment(new Date(data.courseEndTime)).format("HH:mm");
				// } else if (data.courseTimeType == 2) {
				//   data.time = JSON.parse(data.recentRound).courseTimeList;
				//   data.time.forEach((item, i) => {
				//     item.date = moment(item.date).format("yyyy-MM-DD");
				//   });
				// }
				this.courseInfo = data
                console.log(this.courseInfo);
			}
		},
	},
	created() {
		this.getCourseInfo()
	},
}
</script>

<style lang="less" scoped>
.course {
	padding: 2%;
	line-height: 1.5em;
	font-size: 0.9em;
	h3 {
		font-weight: 700;
	}
	p {
		color: #888;
		&.info {
			text-indent: 2em;
		}
	}
}
.richText,
.ql-editor {
	/deep/.ql-align-center {
		text-align: center;
	}
	/deep/img {
		max-width: 100%;
	}
}
</style>
